<template>
  <div class="footer-content py-5">
    <div class="content">
      <div>
        <img class="logo" src="../../assets/logo.png" />
      </div>
      <div class="flex">
        <div class="mr-2">
          <img class="icon" src="../../assets/footer/phone.png" />
        </div>
        <div>
          <div class="title">13087693841</div>
          <div class="mt-1 title">17792184721</div>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2">
          <img class="icon" src="../../assets/footer/email.png" />
        </div>
        <div class="">
          <div class="title">yiluo_m@163.com</div>
          <div class="mt-1 title">652846683@qq.com</div>
        </div>
      </div>
      <div class="flex">
        <img class="icon" src="../../assets/footer/address.png" />
        <div class="title">陕西省西安市长安区</div>
      </div>
    </div>
    <div class="icp">
      <a href="https://beian.miit.gov.cn/">陕ICP备2024024644号-1</a>
    </div>
  </div>
</template>
<script setup lang="ts">

</script>
<style scoped lang="scss">
.footer-content {
  width: 100%;
  background: #212121;
  position: relative;
  .content {
    width: 80%;
    margin: auto;
    color: #ffffff;
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }
  .icp {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    a {
      color: #ffffff;
      text-decoration-line: none;
      &:visited {
        color: #ffffff;
      }
    }
  }
}
.flex {
  display: flex;
  align-items: center;
}
.mr-2 {
  margin-right: 20px;
}
.py-5 {
  padding: 3rem 0;
}
.mt-1 {
  margin-top: 10px;
}
.icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.title {
  cursor: pointer;
  &:hover {
    color: #17a2b8;
  }
}
.logo {
  width: 120px;
  height: 70px;
}
</style>