<template>
  <div class="header-box">
     <div class="content">
       <div>
         <img class="logo" src="../../assets/logo.png" />
       </div>
       <div class="menus">
         <div style="cursor: default">首页</div>
         <div>关于我们</div>
         <div>案例展示</div>
         <div>商务合作</div>
         <div>最新咨询</div>
         <div>联系我们</div>
       </div>
     </div>
  </div>
</template>
<script setup lang="ts">

</script>

<style scoped lang="scss">
.header-box {
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  top: 60px;
  justify-content: center;
  .content {
    width: 70%;
    display: flex;
    justify-content: space-between;
    .menus {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 40px;
       div {
         cursor: pointer;
         color: #fff;
         text-transform: uppercase;
         font-weight: 500;
         font-size: 20px;
         letter-spacing: 2px;
         &:hover {
           color: rgba(0, 0, 0, 0.7);
         }
       }
    }
  }
}
.logo{
  width: 120px;
  height: 70px;
}
</style>