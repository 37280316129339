<template>
  <div class="home">
    <div class="banner">
      <div class="banner-layer">
        <div class="container">
          <div class="agile_banner_info">
            <div class="agile_banner_info1">
              <div id="typed-strings" class="agileits_w3layouts_strings">
                <div>Boost Your <i>Business.</i></div>
                <div class="chinese">提升您的业务.</div>
                <div>We are a creative studio.</div>
                <div class="chinese">我们是创意工作室.</div>
                <div><i>Professional </i>development.</div>
                <div class="chinese">专业开发.</div>
                <div>Make work faster through us.</div>
                <div class="chinese">通过我们让工作变得更快捷.</div>
              </div>
              <span id="typed" style="white-space:pre;"></span>
            </div>
            <div class="banner_agile_para">
              <div>Committed to creating a convenient, fast, and efficient one-stop service platform, providing market demand by integrating merchant resources.</div>
              <div class="chinese">致力于打造方便快捷，高效的一站式服务平台，通过整合商家资源提供市场需求.</div>
            </div>
            <a style="cursor: pointer">了解我们</a>
          </div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <div class="content">
        <div class="flex">
          <div class="head_part">
            <div class="heading">关于我们</div>
            <div class="heading-bottom ">Few Words Of Our Studio</div>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="welcome_left">
            <h3 class="mb-3">小宇工作室</h3>
            <h4 class="mb-3">服务宗旨</h4>
            <p>为客户提供高质量、可靠的定制化软件解决方案。我们的目标是帮助客户实现业务目标，提升效率并提供卓越的用户体验,通过我们专业的团队和对软件开发的深刻理解，我们将不断努力提供优质的软件开发服务，为客户创造价值并提供持久的技术支持</p>
            <a> Read More </a>
            <div class="about-grids">
              <div class="col-md-4 about-grid1">
                <h3>合作与沟通</h3>
                <p>我们重视与客户之间的合作与沟通，深入了解客户的需求和期望，以确保开发出符合其要求的软件解决方案</p>
              </div>
              <div class="col-md-4 about-hover-grid">
                <h3>交付与优质售后</h3>
                <p>我们注重按时交付软件项目，并提供持续的技术支持与维护，确保客户在软件使用过程中获得持续的支持和帮助</p>
              </div>
              <div class="col-md-4 about-hover-grid">
                <h3>客户利益与数据安全</h3>
                <p>我们承诺保护客户的利益和数据安全，并采取相应的安全措施，以确保客户的数据得到妥善保护</p>
              </div>
            </div>
          </div>
        <div class="welcome_right mt-lg-0 mt-5">
            <div class="rows">
              <div class="col-md-3 col-sm-2 col-3 icon color1 text-center">
                <img src="../assets/home/customized.png" />
              </div>
              <div class="grid_info">
                <h3 class="mb-2">定制化开发</h3>
                <p>软件开发的一大特色是能够根据客户的具体需求进行定制化开发。与传统的通用软件相比，定制化开发可以更好地满足客户独特的业务需求，提供个性化的解决方案</p>
              </div>
            </div>
            <div class="rows">
              <div>
                <img src="../assets/home/agile.png" />
              </div>
              <div class="col-md-9 col-sm-10 col-9 grid_info">
                <h3 class="mb-2">敏捷开发方法</h3>
                <p>敏捷开发注重与客户的合作与反馈，以迭代和增量的方式进行开发，快速响应变化并及时调整开发方向，提高开发效率和产品质量</p>
              </div>
            </div>
            <div class="rows">
              <div>
                <img src="../assets/home/deliver.png" />
              </div>
              <div class="col-md-9 col-sm-10 col-9 grid_info">
                <h3 class="mb-2">持续集成与交付</h3>
                <p>通过自动化的构建、测试和部署流程，可以不断集成开发团队的代码，并将高质量的软件交付给客户。这样可以加快软件发布速度，提高产品的可靠性和稳定性</p>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="about-us service-content">
      <div class="content">
        <div class="flex">
          <div class="head_part">
            <div class="heading">服务</div>
            <div class="heading-bottom ">Service targets of the studio</div>
          </div>
        </div>
      </div>
      <div class="service-list">
        <div class="welcome_right mt-lg-0 mt-5">
          <div class="rows-service">
            <div class="col-md-3 col-sm-2 col-3 icon color1 text-center">
              <img class="img-icon" src="../assets/home/enterprise.png" />
            </div>
            <div class="grid_info">
              <h3 class="mb-2">企业和组织</h3>
              <p>中小型企业、初创企业和非营利组织等都可能需要软件开发服务。
                他们可能需要开发自己的定制化企业级应用程序、管理系统、电子商务平台等，
                以支持业务运营和增强竞争力</p>
            </div>
          </div>
          <div class="rows-service">
            <div>
              <img class="img-icon" src="../assets/home/government.png" />
            </div>
            <div class="col-md-9 col-sm-10 col-9 grid_info">
              <h3 class="mb-2">政府部门</h3>
              <p>政府机构、公共服务部门和政府项目通常需要软件开发服务来开发和维护各种类型的应用程序、信息管理系统、电子政务平台等，以提高效率和服务质量</p>
            </div>
          </div>
          <div class="rows-service">
            <div>
              <img class="img-icon" src="../assets/home/science.png" />
            </div>
            <div class="grid_info">
              <h3 class="mb-2">学术机构和研究机构</h3>
              <p>学术界和研究机构需要软件开发服务来开发研究工具、科学计算软件、数据分析平台等，以支持科学研究和学术工作</p>
            </div>
          </div>
        </div>
        <div class="welcome_right mt-lg-0 mt-5">
          <div class="rows-service">
            <div class="col-md-3 col-sm-2 col-3 icon color1 text-center">
              <img class="img-icon" src="../assets/home/hospital.png" />
            </div>
            <div class="grid_info">
              <h3 class="mb-2">医疗机构</h3>
              <p>医院、诊所和其他医疗机构可能需要软件开发服务来开发医疗信息管理系统、电子病历系统、医疗设备软件等，以提高医疗服务的效率和质量</p>
            </div>
          </div>
          <div class="rows-service">
            <div>
              <img class="img-icon" src="../assets/home/education.png" />
            </div>
            <div class="col-md-9 col-sm-10 col-9 grid_info">
              <h3 class="mb-2">教育机构</h3>
              <p>学校、大学和培训机构可能需要软件开发服务来开发教育管理系统、在线学习平台、虚拟实验室等，以支持学习和教育过程</p>
            </div>
          </div>
          <div class="rows-service">
            <div>
              <img class="img-icon" src="../assets/home/setup.png" />
            </div>
            <div class="col-md-9 col-sm-10 col-9 grid_info">
              <h3 class="mb-2">个人开发者和创业者</h3>
              <p>个人开发者和创业者可能需要软件开发服务来将他们的创意转化为具体的软件产品或应用，以满足市场需求</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pricing-layer">
      <div class="container py-3">
        <div class="content teams">
          <div class="flex">
            <div class="head_part">
              <div class="heading">案例展示</div>
              <div class="heading-bottom ">Service cases of the studio</div>
            </div>
          </div>
        </div>
        <div class="prcing-grids">
            <div>
              <img src="../assets/home/tangxin.jpg" />
              <div>糖心科德</div>
            </div>
            <div>
              <img src="../assets/home/huade.jpg" />
              <div>华德航天</div>
            </div>
            <div>
              <img src="../assets/home/knows.jpg" />
              <div>知识通小程序</div>
            </div>
            <div>
              <img src="../assets/home/shuzi.jpg" />
              <div class="title">数字人生</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

</script>
<style lang="scss">
.banner {
  background: url(../assets/banner1.jpg) no-repeat 0 0;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  min-height: 770px;
  position: relative;
}
.chinese {
  color: #ffffff;
  margin: 5px 0 10px 0;
}
.banner-layer {
  background: url(../assets/layer.png)repeat;
  background: rgba(0, 0, 0, 0.5);
  min-height: 770px;
  .container {
    max-width: 1140px;
  }
}
.agile_banner_info {
  padding: 18em 0 0;
}
.agile_banner_info1 {
  margin-left: 0;
}
.banner_agile_para {
  margin-right: 10em;
}
.banner_agile_para p {
  color: #fff;
  margin: 1em 0;
  line-height: 2em;
  letter-spacing: 2px;
}
.agile_banner_info a {
  padding: 12px 35px;
  color: #fff;
  background: #17a2b8;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  margin-top: 1.5em;
  border-radius: 35px;
}
.agile_banner_info a:hover {
  background: #fd5c63;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.content {
  .head_part {
    padding-left: 1em;
    border-left: 4px solid #17a2b8;
  }
  .heading {
    font-size: 32px;
    font-weight: 600;
    color: #232323;
  }
  .heading-bottom {
    margin-top: 10px;
    color: #a6a6a6;
    letter-spacing: 2px;
    font-size: 15px;
  }
}
.teams {
  .head_part {
    padding-left: 1em;
    border-left: 4px solid #ffffff;
  }
  .heading {
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
  }
  .heading-bottom {
    margin-top: 10px;
    color: #a6a6a6;
    letter-spacing: 2px;
    font-size: 18px;
  }
}
.about-us {
  width: 70%;
  margin: auto;
  padding-top: 3rem;
  .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 200px;
    .mb-3, .my-3 {
      margin-bottom: 1rem !important;
    }
    .welcome_left {
      h3 {
        font-size: 24px;
        color: #222;
        letter-spacing: 1px;
        text-transform: capitalize;
      }
      h4 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800;
        color: #888;
        letter-spacing: 5px;
      }
      p {
        font-size: 15px;
        letter-spacing: .5px;
        line-height: 28px;
        color: #666;
      }
      a {
        padding: 12px 35px;
        color: #fff;
        background: #17a2b8;
        font-size: 15px;
        letter-spacing: 1px;
        display: inline-block;
        margin-top: 1.5em;
        border-radius: 35px;
        cursor: pointer;
        &:hover {
          background: #fd5c63;
        }
      }
      .about-grids {
        border-top: 1px solid #fd5c63;
        margin-top: 2em;
      }
      .about-grids {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        cursor: pointer;
        h3 {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 800;
          color: #fd5c63;
          letter-spacing: 3px;
          margin-bottom: 5px;
          margin-top: 15px;
        }
        .about-grid1 {
          padding-top: 1em;
          border-top: 2px solid #fd5c63;
        }
        .about-hover-grid:hover {
          border-top: 2px solid #fd5c63;
        }
        .about-hover-grid {
          padding-top: 1em;
          border-top: 2px solid transparent;
        }
      }
    }
    .welcome_right {
      .row {
        display: flex;
        align-items: center;
      }
      h3 {
        font-size: 22px;
        color: #333;
        letter-spacing: 1px;
      }
      p {
        font-size: 15px;
        letter-spacing: .5px;
        line-height: 28px;
        color: #666;
      }
    }
  }
  .rows {
    display: flex;
    align-items: center;
    height: 180px;
    img {
      margin-right: 50px;
      position: relative;
      top: -20px;
    }
  }
}
.service-content {
  margin-top: 40px;
  .service-list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 100px;
    grid-row-gap: 20px;
  }
}
.rows-service {
  display: flex;
  align-items: center;
  height: 130px;
  img {
    margin-right: 50px;
    margin-top: -40px;
  }
}
.pricing-layer {
  background: #17a2b8;
  padding: 3rem 0;
  .container {
    width: 70%;
    margin: auto;
  }
  .prcing-grids {
    padding: 3rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 40px;
    img {
      width: 100%;
      height: 300px;
      object-fit: contain;
    }
    div {
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
